@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

html, body, #root, .App {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.main-content{
  height: 100%
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  margin-bottom: 30px;
}

#ui-header{
  border-left-color: none;
  border-right-color: none;
  border-color: white;
  box-shadow: none;
  border-style: none;
  display: flex;
}

#home-content{
  top: 25%;
  flex-shrink: 1;
}

#project-pic{
  height: 400px;
  flex-shrink: 1;
}

#col-grid{
  margin-left: 200px;
  margin-right: 200px;
}

.App-link {
  color: #61dafb;
}

.user-name {
  color: black;
  font-family: 'Quicksand', sans-serif;
  font-size: 7.5em;
  top: 50%;
  left: 50%;
}
.home-p-tag{
  color: black;
  font-size: 2em;
  font-family: 'Quicksand', sans-serif;
  top: 50%;
  left: 50%;
  margin-bottom: 0;
  flex-shrink: 1;
}

.sm-nav-a{
  color: black;
  font-size: 20px;
  font-family: 'Quicksand', sans-serif;
  position: relative;
  text-decoration: none;
}
.med-nav-a{
  color: black;
  font-size: 30px;
  font-family: 'Quicksand', sans-serif;
  position: relative;
  text-decoration: overline;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.column-1 {
  flex: 50%;
  position: absolute;
  grid-column: 1/8;
  grid-row: 1;

}
.column-2 {
  flex: 50%;
  overflow: hidden;
  grid-column: 2;
  grid-row: 1;
}
.column-3 {
  flex: 50%;
  overflow: hidden;
  grid-column: 1;
  grid-row: 2;
}

.home-img {
  padding-top: 50px;
  max-width:100%;
  max-height:80%;
  border-radius: 20%;
  height: auto;
  flex-shrink: 1;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
}

.home-card-1{
  border: grey solid 1px;
  padding: 1rem;
  width: 40rem;
  height: 45rem;
  display: inline-grid;
  padding: 60px;
  position: relative;
  border: none;
}
.home-card-2{
  text-align: center;

  padding: 1rem;
  width: 30rem;
  height: 35rem;
  display: inline-grid;
  margin: 1rem 1rem;
  padding: 60px;
  position: relative;
  border: none;
}

.card{
  text-align: center;
  border: grey solid 1px;
  padding: 1rem;
  width: 40rem;
  height: 45rem;
  display: inline-grid;
  margin: 1rem 1rem;
  padding: 60px;
  position: relative;
}

.page-header{
  font-size: 3em;
  color: black;
  font-family: 'Quicksand', sans-serif;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.projects-paragraph{
  font-size: 1.5em;
}

.projects-header{
  font-size: 3em;
  text-decoration: none;
  style: bold;
  color: black;
}
.projects-link{
  font-size: 1.5em;
  text-decoration: none;
  font-weight: bold;
  color: black;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}
.general-h-tag {
  position: centered;
  color: black;
  font-size: 3em;
  text-decoration: underline;
  font-family: 'Quicksand', sans-serif;
}
.general-p-tag {
  position: centered;
  color: black;
  font-size: 1.5em;
  font-family: 'Quicksand', sans-serif;
  padding-left: 100px;
  padding-right: 100px;
}
.about-p-tag {
  color: black;
  font-size: 1.5em;
  font-family: 'Quicksand', sans-serif;
}
.about-header-tag {
  color: black;
  font-weight:bold;
  font-size: 2em;
  font-family: 'Quicksand', sans-serif;
}

#menu-header {
  display: flex;
}

.contact-div-tag{
  display: inline-flex;
}

.home-icon{
  margin-right: 2em;
}

.navbar-icons{
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 20px;
  font-size: 2em;
}

.nav-a{
  color: black;
  font-size: 50px;
  text-decoration: none;
  font-family: 'Quicksand', sans-serif;
}

.prof-pic{
  border-radius: 20%;
  width: 40%;
  height: 40%;
}
#contact-icons{
  display: inline-flex;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
